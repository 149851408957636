
import { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const FAQs = () => {
    const { t } = useTranslation();
    const [expandedIndex, setExpandedIndex] = useState(null);
    const faqs = [
        {
            question: "What services do you offer?",
            answer: "We provide a wide range of services, including digital marketing, web development, app design and development, advertising and promotion, graphic design, and business and marketing consultations."
        },
        {
            question: "How can I request a service?",
            answer: "You can request a service by contacting us through the contact form on our website, emailing us, or calling our customer service number. We'll discuss your needs and provide you with a tailored solution."
        },
        {
            question: "Do you offer customized packages or subscriptions?",
            answer: "Yes, we offer customized subscription packages that allow customers to subscribe to a bundle of services or receive special discounts. Packages can be monthly or yearly, designed to meet different user needs.\n"
        },
        {
            question: "Can I book appointments or consultations?",
            answer: "Absolutely! We have a comprehensive appointment booking system that allows you to select available services, schedule suitable times, and receive automated confirmations and reminders via email or SMS."
        },
        {
            question: "Do you work with international clients",
            answer: "Yes, we work with clients from all over the world. Our team is equipped to handle projects remotely and communicate effectively regardless of your location."
        },

        {
            question: "What is the process to start a new project?",
            answer: "The process begins with an initial consultation to understand your goals and requirements. We then develop a strategy and a customized proposal. Upon agreement, we start the design and development while keeping you informed throughout the project."
        },
        {
            question: "How do you ensure the quality of your services?",
            answer: "We have a specialized team of professionals who follow best practices and industry standards. We conduct thorough testing and quality assurance to deliver high-quality results."
        },
        {
            question: "What payment methods do you accept?",
            answer: "We accept multiple payment methods, including credit cards, bank transfers, and electronic payment platforms. Specific details can be discussed during the proposal stage."
        },
        {
            question: "Can you provide examples of your previous work?",
            answer: "Yes, please visit our portfolio section to see examples of projects we have completed for our clients."
        },
        {
            question: "How can I contact you for more information?",
            answer: "You can reach us through the contact form on our website, email us at [your email address], or call us at [your phone number]. We are here to assist you with any inquiries."
        },
        {
            question: "What is the typical timeline for project completion?",
            answer: "The project timeline varies based on the scope and complexity of the work required. We will provide you with an estimated schedule after discussing your requirements and defining the project scope."
        },
        {
            question: "Do you offer post-delivery support services?",
            answer: "Yes, we offer post-delivery support and maintenance to ensure the solutions provided continue to function efficiently. We can also provide updates and upgrades based on your future needs."
        },
        {
            question: "How do you price your services?",
            answer: "Our service pricing is determined based on the scope of work, technical requirements, and project duration. After understanding your needs, we'll provide you with a detailed and transparent quotation."
        },
        {
            question: " Can you handle custom projects or special requirements?",
            answer: "Absolutely! We have the expertise to handle custom projects and unique requirements. We'll work closely with you to understand your objectives and provide tailored solutions to meet your needs."
        },
        {
            question: "What technologies and tools do you use?",
            answer: "We utilize a variety of modern technologies and advanced tools in programming, design, and digital marketing, such as HTML5, CSS3, JavaScript, React, Node.js, and design tools like Figma and Adobe Creative Suite."
        },
        {
            question: "Do you provide training or workshops?",
            answer: "Yes, we offer training sessions and customized workshops for our clients, whether to teach them how to manage their websites and applications or to provide an overview of best practices in digital marketing."
        },
        {
            question: "How do you handle confidentiality of information?",
            answer: "We place the utmost importance on the confidentiality and security of our clients' information. We adhere to Non-Disclosure Agreements (NDAs) and apply high security standards to protect your data.\n"
        },
        {
            question: "Can I modify or update the project after it has started?",
            answer: "Yes, we believe in flexible collaboration. You can request modifications or updates during the project stages, and we will work to integrate them in line with the schedule and budget."
        },
        {
            question: "What languages do you support in your projects?",
            answer: "Our team is multilingual, and we can develop projects and support content in both Arabic and English, helping you reach a wider audience."
        },
        {
            question: "How is communication handled during the project?",
            answer: "We believe in continuous communication. We will establish preferred communication channels (such as email, phone, or messaging apps) and set regular meeting schedules to keep you informed of the project's progress."
        },
    ];


    const toggleAnswer = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index);
    };

    return (
        <div dir={i18n.dir()} className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <h1 className="text-3xl md:text-6xl text-blue-950 font-semibold mt-12">{t('faqs.title')}</h1>
            <p className="text-1xl text-center md:text-3xl text-gray-700 font-normal mt-4">{t('faqs.subtitle')}</p>
            <div className="mt-8 w-full max-w-2xl">
                {t('faqs.questions', { returnObjects: true }).map((faq, index) => (
                    <div key={index} className="mb-4 p-4 bg-white rounded-lg shadow">
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleAnswer(index)}>
                            <h2 className="text-xl text-blue-900 font-semibold">{faq.question}</h2>

                            {expandedIndex === index ? (
                                <FiChevronUp className="text-blue-900 text-2xl" />
                            ) : (
                                <FiChevronDown className="text-blue-900 text-2xl" />
                            )}
                        </div>

                        {expandedIndex === index && (
                            <p className="text-gray-700 mt-2">{faq.answer}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQs;