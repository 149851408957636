import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from "react-icons/io5";
import { GrFormNext } from "react-icons/gr";
import { BsMegaphone } from "react-icons/bs";
import { SiDigitalocean } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { GrAppsRounded } from "react-icons/gr";
import { RiAdvertisementFill } from "react-icons/ri";
import { SiTaichigraphics } from "react-icons/si";
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/contact-us');
    };

    const handleClick = () => {
        navigate('/services');
    };

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videos = ['/home1.mp4', '/home5.mp4'];

    const videoRef = useRef(null);

    useEffect(() => {
        const videoDuration = 10000;
        const timer = setTimeout(() => {
            handleNext();
        }, videoDuration);

        return () => clearTimeout(timer);
    }, [currentVideoIndex]);

    const handleNext = () => {
        setCurrentVideoIndex((prevIndex) =>
            prevIndex === videos.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrevious = () => {
        setCurrentVideoIndex((prevIndex) =>
            prevIndex === 0 ? videos.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [currentVideoIndex]);

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="relative flex items-center  justify-center w-full ">
                <video
                    ref={videoRef}
                    src={videos[currentVideoIndex]}
                    autoPlay
                    muted
                    controls
                    className="w-full h-auto object-cover z-0"
                ></video>

                <div className="absolute inset-0 flex justify-between items-center p-4 z-10">
                    <button
                        onClick={handlePrevious}
                        className="bg-white text-black p-4 rounded-full shadow-lg cursor-pointer hover:bg-gray-300 transition duration-200"
                    >
                        <IoChevronBack />
                    </button>
                    <button
                        onClick={handleNext}
                        className="bg-white text-black p-4 rounded-full shadow-lg cursor-pointer hover:bg-gray-300 transition duration-200"
                    >
                        <GrFormNext />
                    </button>
                </div>

                <div className="absolute w-full max-w-[45%] inset-y-0 ml-4 md:ml-14 left-0 flex items-center pl-4 md:pl-8 animate-from-right">
                    <h1 className="text-white text-1xl md:text-4xl font-bold text-center md:text-left">{t('home.hero.title')}</h1>
                </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-8xl p-8 bg-[#1E314B] shadow-lg rounded-lg h-auto mt-28">
                <div className="w-full md:w-1/2 text-left">
                    <h1 className="text-3xl md:text-4xl text-white font-bold mb-4">{t('home.features.title')}</h1>
                    <p className="text-lg mb-6 text-white">
                        {t('home.features.description')}
                    </p>
                    <button onClick={handleClick}
                            className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition">
                        {t('home.features.buttonText')}
                    </button>
                </div>

                <div className="w-full flex flex-col md:w-1/2 text-left md:ml-12 mt-12 md:mt-0">
                    <ol className="text-gray-200 text-2xl md:text-4xl text-left mt-4 font-bold">
                        <li className="flex mt-4 animate-from-left"><SiDigitalocean className="mr-4 ml-4"/>{t('home.feature.digitalMarketing')}</li>
                        <li className="flex mt-2 animate-from-right"><CgWebsite className="mr-4 ml-4"/>  {t('home.feature.webDevelopment')}</li>
                        <li className="flex mt-2 animate-from-left"><GrAppsRounded className="mr-4 ml-4"/>{t('home.feature.appDesignDevelopment')}</li>
                        <li className="flex mt-2 animate-from-right"><RiAdvertisementFill className="mr-4 ml-4"/>{t('home.feature.advertisingPromotion')}</li>
                        <li className="flex mt-2 animate-from-left"><SiTaichigraphics className="mr-4 ml-4"/>{t('home.feature.graphicDesign')}</li>
                        <li className="flex mt-2 animate-from-right"><BsMegaphone className="mr-4 ml-4"/> {t('home.feature.businessMarketingConsultation')}</li>
                    </ol>
                </div>
            </div>

            <div className="relative flex flex-col w-full max-w-8xl bg-gray-100 shadow-lg rounded-lg mt-28">
                <video className="" controls src="/home9.mp4">
                    Your browser does not support the video tag.
                </video>
                <div className="absolute left-0 right-0 md:mt-96 p-14 md:p-20 text-white font-bold text-center">
                    <p className="text-1xl md:text-6xl">
                        {t('home.callToAction.videoDescription')}
                    </p>
                </div>
            </div>

            <div className="w-full flex flex-col items-center justify-center p-12 mb-20 mt-20 bg-gray-200 text-center">
                <h2 className="text-2xl md:text-3xl font-semibold mb-4">{t('home.callToAction.title')}</h2>
                <p className="text-lg mb-6">
                    {t('home.callToAction.description')}
                </p>
                <button onClick={handleButtonClick}
                        className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition">
                    {t('home.callToAction.buttonText')}
                </button>
            </div>
        </div>
    );
};

export default Home;
