import React, { useRef, useState } from 'react';
import CustomInput from "../common/CustomInput/CustomInput";
import CustomButton from "../common/CustomButton/CustomButton";
import { contactTemplateId, publicKey, serviceId } from "../constants/Index";
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const ContactUs = () => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form data before submission:", formRef.current);

        try {
            const response = await emailjs.sendForm(
                serviceId,
                contactTemplateId,
                formRef.current,
                publicKey
            );
            console.log("EmailJS response:", response);
            alert('Message sent successfully!');
        } catch (error) {
            console.error("EmailJS error:", error);
            alert('Failed to send message. Please try again.');
        }
    };

    return (
        <div dir={i18n.dir()} className="min-h-screen  flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                <h1 className="text-4xl font-semibold mb-6 text-center">{t('contactUs.title')}</h1>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <CustomInput
                        label={t('contactUs.form.name.label')}
                        name="name"
                        value={formData.name}
                        onChange={(value) => handleInputChange('name', value)}
                        placeholder={t('contactUs.form.name.placeholder')}
                        isCompulsory={true}
                    />
                    <CustomInput
                        label={t('contactUs.form.email.label')}
                        name="email"
                        value={formData.email}
                        onChange={(value) => handleInputChange('email', value)}
                        placeholder={t('contactUs.form.email.placeholder')}
                        isCompulsory={true}
                        type="email"
                    />
                    <CustomInput
                        label={t('contactUs.form.message.label')}
                        name="message"
                        value={formData.message}
                        onChange={(value) => handleInputChange('message', value)}
                        placeholder={t('contactUs.form.message.placeholder')}
                        isCompulsory={true}
                        isTextArea={true}
                    />
                    <div className="flex justify-center mt-6">
                        <CustomButton text={t('contactUs.form.submit.text')} theme="blue" size="medium" type="submit"/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
