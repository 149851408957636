import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import FAQs from "./components/FAQs";
import './App.css';
import ScrollToTop from "./components/ScrollToTop";
import LanguageSelector from "./LanguageSelector";
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { useState } from 'react';
import "./i18n";
import { useTranslation } from 'react-i18next';
import i18n from "./i18n";

function App() {
    const { t } = useTranslation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <Router>
            <div dir={i18n.dir()} className="flex flex-col min-h-screen">
                <header className="sticky top-0 bg-gray-100 p-4 z-50 shadow-lg">
                    <nav className="container mx-auto flex items-center justify-between h-12">
                        <NavLink to="/">
                            <img src="/logo.png" alt="Logo" className="h-24 w-auto object-contain" />
                        </NavLink>


                        <button onClick={toggleMobileMenu} className="md:hidden">
                            {isMobileMenuOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
                        </button>


                        <div className="hidden md:flex flex-grow justify-center gap-12">

                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.home')}
                            </NavLink>

                            {["Services", "Portfolio", "FAQs", "About"].map((label, index) => (
                                <NavLink
                                    key={index}
                                    to={`/${label.toLowerCase().replace(" ", "-")}`}
                                    className={({ isActive }) =>
                                        isActive
                                            ? "text-orange-500 transition duration-300"
                                            : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                    }
                                >
                                    {t(`app.${label.toLowerCase().replace(" ", "")}`)}
                                </NavLink>
                            ))}
                            <NavLink
                                to="/contact-us"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.contactUs')}
                            </NavLink>
                        </div>


                        <div className="hidden md:block">
                            <LanguageSelector />
                        </div>
                    </nav>


                    {isMobileMenuOpen && (
                        <div className="md:hidden flex flex-col items-center space-y-4 mt-4">

                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.home')}
                            </NavLink>
                            {["Services", "Portfolio", "FAQs", "About"].map((label, index) => (
                                <NavLink
                                    key={index}
                                    to={`/${label.toLowerCase().replace(" ", "-")}`}
                                    onClick={() => setIsMobileMenuOpen(false)}
                                    className={({ isActive }) =>
                                        isActive
                                            ? "text-orange-500 transition duration-300"
                                            : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                    }
                                >
                                    {t(`app.${label.toLowerCase().replace(" ", "")}`)}
                                </NavLink>
                            ))}

                            <NavLink
                                to="/contact-us"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.contactUs')}
                            </NavLink>


                            <LanguageSelector />
                        </div>
                    )}
                </header>

                <ScrollToTop />

                <main className="flex-grow">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/portfolio" element={<Portfolio />} />
                        <Route path="/faqs" element={<FAQs />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                    </Routes>
                </main>

                <Footer />
            </div>
        </Router>
    );
}

export default App;