import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import i18n from "../i18n";

const Portfolio = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/contact-us');
    };

    return (
        <div dir={i18n.dir()} className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <h1 className="md:text-6xl text-3xl text-blue-950 md:mt-20 mt-10 font-semibold mb-8 sm:mb-12 text-center">
                {t('portfolio.title')}
            </h1>

            <div className="flex flex-col md:flex-row items-center justify-between w-11/12 sm:w-4/5 mb-10">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/portfolio.jpeg"
                        alt="Digital Marketing Portfolio Image"
                        className="w-full sm:w-[80%] h-[300px] sm:h-[400px] md:h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl text-blue-950 font-bold mb-4 text-center md:text-left">
                        {t('portfolio.webDevelopment.header')}
                    </h2>
                    <p className="text-xl sm:text-2xl md:text-3xl text-gray-700 pr-0 sm:pr-4 text-center md:text-left">
                        {t('portfolio.webDevelopment.description')}
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center justify-between w-11/12 sm:w-4/5 mb-10">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/portfolio1.webp"
                        alt="Technology Portfolio Image"
                        className="w-full sm:w-[90%] h-[300px] sm:h-[400px] md:h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pr-8 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl text-blue-950 font-bold mb-4 text-center md:text-left">
                        {t('portfolio.mobileAppDevelopment.header')}
                    </h2>
                    <p className="text-xl sm:text-2xl md:text-3xl text-gray-700 text-center md:text-left pl-0 sm:pl-4">
                        {t('portfolio.mobileAppDevelopment.description')}
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between w-11/12 sm:w-4/5 mb-10">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/image7.webp"
                        alt="Digital Marketing Portfolio Image"
                        className="w-full sm:w-[80%] h-[300px] sm:h-[400px] md:h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl text-blue-950 font-bold mb-4 text-center md:text-left">
                        {t('portfolio.digitalMarketing.header')}
                    </h2>
                    <p className="text-xl sm:text-2xl md:text-3xl text-gray-700 text-center md:text-left pr-0 sm:pr-4">
                        {t('portfolio.digitalMarketing.description')}
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center justify-between w-11/12 sm:w-4/5 mb-10">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/image8.jpg"
                        alt="Technology Portfolio Image"
                        className="w-full sm:w-[90%] h-[300px] sm:h-[400px] md:h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pr-8 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl text-blue-950 font-bold mb-4 text-center md:text-left">
                        {t('portfolio.graphicDesign.header')}
                    </h2>
                    <p className="text-xl sm:text-2xl md:text-3xl text-gray-700 text-center md:text-left pl-0 sm:pl-4">
                        {t('portfolio.graphicDesign.description')}
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between w-11/12 sm:w-4/5 mb-10">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/portfolio4.jpeg"
                        alt="Digital Marketing Portfolio Image"
                        className="w-full sm:w-[80%] h-[300px] sm:h-[400px] md:h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl text-blue-950 font-bold mb-4 text-center md:text-left">
                        {t('portfolio.advertising.header')}
                    </h2>
                    <p className="text-xl sm:text-2xl md:text-3xl text-gray-700 text-center md:text-left pr-0 sm:pr-4">
                        {t('portfolio.advertising.description')}
                    </p>
                </div>
            </div>

            <div className="w-full flex flex-col items-center justify-center p-8 sm:p-12 mb-12 bg-gray-200 text-center">
                <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                    {t('portfolio.howCanWeHelp')}
                </h2>
                <p className="text-lg sm:text-xl mb-6">
                    {t('portfolio.readyToInnovate')}
                </p>
                <button
                    onClick={handleButtonClick}
                    className="bg-blue-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg hover:bg-blue-700 transition">
                    {t('portfolio.callToAction')}
                </button>
            </div>
        </div>
    );
};

export default Portfolio;
